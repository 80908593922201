<template>
  <div class="app-container water-energy-consumption">
    <div class="search-line">
      <WaterEnergySearch />
    </div>
    <div class="card-container">
      <el-row :gutter="30">
        <el-col :span="10">
          <!-- 园区用水量总览 -->
          <WaterOutTotal />
        </el-col>
        <el-col :span="14">
          <!-- 园区进水量总览 -->
          <WaterInTotal />
        </el-col>
      </el-row>
    </div>
    <div class="card-container">
      <el-row :gutter="30">
        <el-col :span="16">
          <div>
            <!-- 当月每日用水量/进水量变化趋势(单位:吨) -->
            <WaterMonthTrend />
          </div>
          <el-row :gutter="30" style="margin-top: 20px">
            <el-col :span="12">
              <!-- 中水不同渠道进水量占比分析 -->
              <AnalysisOfWaterInflowProportion />
            </el-col>
            <el-col :span="12">
              <!-- 各楼栋用水量比较分析 -->
              <ComparativeAnalysisOfWater />
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="8">
          <!-- 园区用水概况 -->
          <ParkWaterOverview />
        </el-col>
      </el-row>
    </div>
    <div class="card-container">
      <el-row :gutter="30">
        <el-col :span="16">
          <!-- 4栋当月每日用水量变化趋势(单位:吨) -->
          <BuildingFourTrend />
        </el-col>
        <el-col :span="8">
          <!-- 园区中水系统的经济效益 -->
          <EconomicBenefits />
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import WaterEnergySearch from "@/components/WaterEnergySearch";
import WaterOutTotal from "./components/waterOutTotal.vue";
import WaterInTotal from "./components/waterInTotal.vue";
import ParkWaterOverview from "./components/parkWaterOverview.vue";
import WaterMonthTrend from "./components/waterMonthTrend.vue";
import ComparativeAnalysisOfWater from "./components/comparativeAnalysisOfWater.vue";
import AnalysisOfWaterInflowProportion from "./components/analysisOfWaterInflowProportion.vue";
import BuildingFourTrend from "./components/buildingFourTrend.vue";
import EconomicBenefits from "./components/economicBenefits.vue";
export default {
  name: "waterEnergyConsumption",
  components: {
    WaterOutTotal,
    WaterInTotal,
    WaterEnergySearch,
    ParkWaterOverview,
    WaterMonthTrend,
    ComparativeAnalysisOfWater,
    AnalysisOfWaterInflowProportion,
    BuildingFourTrend,
    EconomicBenefits,
  },
};
</script>

<style lang="less" scoped>
.water-energy-consumption {
  margin: 10px 20px 20px 40px;
  background: none;
  padding-left: 0;
  .card-container {
    margin-top: 20px;
  }
  ::v-deep .el-input__inner {
    height: 40px;
    line-height: 40px;
    border-radius: 10px;
    width: 160px;
    font-size: 14px;
    font-weight: 500;
    color: #152c5b;
  }
}
</style>
<template>
  <div class="compare-water-proportion">
    <WaterEnergyTitle title="中水不同渠道进水量占比分析" />
    <div class="chart-container" id="waterInAnalysis"></div>
  </div>
</template>

<script>
import WaterEnergyTitle from "@/components/WaterEnergyTitle";
import * as echarts from "echarts";
export default {
  name: "compareWaterProportion",
  components: {
    WaterEnergyTitle,
  },
  mounted() {
    this.initCharts();
  },
  methods: {
    initCharts(datas) {
      const chartDom = document.getElementById("waterInAnalysis");
      this.myChart = echarts.init(chartDom);
      let option = {
        color: [
          "#4b1aa6",
          "#3d39df",
          "#396bdf",
          "#3298e0",
          "#48b8d0",
          "#56cec7",
          "#87cf99",
          "#7949cf",
          "#b169de",
          "#8b62d1",
        ],
        grid: {
          left: "0",
          right: "0",
          bottom: "0",
          top: "0",
        },
        tooltip: {
          trigger: "item",
          padding: 10,
          borderWidth: 0,
          backgroundColor: "#00000099",
          textStyle: {
            color: "#152C5B",
          },
          formatter(params) {
            // let domStr = "<div class='inspection-tips-main'>";
            // domStr += `<span class="tips-circle" style="background: ${params.color}"></span><span>${params.name}</span>`;
            // domStr += "</div>";
            return domStr;
          },
        },
        series: [
          {
            type: "pie",
            radius: [20, 80],
            center: ["50%", "50%"],
            // roseType: "area",
            // roseType: "radius",
            itemStyle: {
              borderRadius: 8,
            },
            label: {
              show: true,
              color: "#152C5B",
              fontSize: 12,
            },
            data: datas || [
              {
                value: 40,
                name: "一期客服楼宇(7,10栋)\n比例:36%",
              },
              {
                value: 38,
                name: "一期客服楼宇(7,11栋)\n比例:36%",
              },
              {
                value: 32,
                name: "一期客服\n(8,10,11栋)-36%",
              },
            ],
          },
        ],
      };
      this.myChart.setOption(option, false);
    },
  },
};
</script>

<style lang="less" scoped>
.compare-water-proportion {
  height: 270px;
  background: #ffffff;
  border-radius: 10px;
  padding: 14px 20px;
  .chart-container {
    width: 100%;
    height: 210px;
    margin-top: 10px;
  }
}
</style>
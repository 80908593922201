<template>
  <div class="park-water-overview">
    <WaterEnergyTitle title="园区用水概况" />
    <div class="title-line first-title">
      <img src="@/assets/images/rdata/water_sz_icon.png" alt="" />
      <span class="title-label"> 园区市政用水概况 </span>
    </div>
    <div class="card-container">
      <div
        class="single-card"
        v-for="(item, index) in szWaterDatas"
        :key="index"
      >
        <div
          class="value-line"
          :style="{
            color: index === 0 ? '#2A61FF' : '#152C5B',
          }"
        >
          {{ item.value | addCommas }}
        </div>
        <div class="label-line">
          {{ item.label }}
        </div>
      </div>
    </div>
    <div class="title-line second-title">
      <img src="@/assets/images/rdata/water_park_icon.png" alt="" />
      <span class="title-label"> 园区中水用水概况 </span>
    </div>
    <div class="card-container">
      <div
        class="single-card"
        v-for="(item, index) in parkWaterDatas"
        :key="index"
      >
        <div
          class="value-line"
          :style="{
            color: index === 0 ? '#2A61FF' : '#152C5B',
          }"
        >
          {{ item.value | addCommas }}
        </div>
        <div class="label-line">
          {{ item.label }}
        </div>
      </div>
    </div>
    <div class="title-line third-title">
      <img src="@/assets/images/rdata/water_analysis_icon.png" alt="" />
      <span class="title-label"> 不同类别用水量占比分析 </span>
    </div>
    <div class="chart-container" id="diffrentTypeCompare"></div>
  </div>
</template>

<script>
import WaterEnergyTitle from "@/components/WaterEnergyTitle";
import * as echarts from "echarts";
export default {
  name: "parkWaterOverview",
  components: {
    WaterEnergyTitle,
  },
  data() {
    return {
      szWaterDatas: [
        {
          label: "本期用水量(吨)",
          value: "10222",
        },
        {
          label: "上月用水量(吨)",
          value: "22",
        },
        {
          label: "环比(%)",
          value: "102",
        },
      ],
      parkWaterDatas: [
        {
          label: "本期用水量(吨)",
          value: "10222",
        },
        {
          label: "上月用水量(吨)",
          value: "22",
        },
        {
          label: "环比(%)",
          value: "102",
        },
      ],
    };
  },
  mounted() {
    this.initCharts();
  },
  methods: {
    initCharts(datas) {
      const chartDom = document.getElementById("diffrentTypeCompare");
      this.myChart = echarts.init(chartDom);
      let option = {
        color: [
          "#4b1aa6",
          "#3d39df",
          "#396bdf",
          "#3298e0",
          "#48b8d0",
          "#56cec7",
          "#87cf99",
          "#7949cf",
          "#b169de",
          "#8b62d1",
        ],
        grid: {
          left: "0",
          right: "0",
          bottom: "0",
          top: "0",
        },
        tooltip: {
          trigger: "item",
          padding: 10,
          borderWidth: 0,
          backgroundColor: "#00000099",
          textStyle: {
            color: "#152C5B",
          },
          formatter(params) {
            // let domStr = "<div class='inspection-tips-main'>";
            // domStr += `<span class="tips-circle" style="background: ${params.color}"></span><span>${params.name}</span>`;
            // domStr += "</div>";
            return domStr;
          },
        },
        series: [
          {
            type: "pie",
            radius: [20, 80],
            center: ["50%", "50%"],
            // roseType: "area",
            // roseType: "radius",
            itemStyle: {
              borderRadius: 8,
            },
            label: {
              show: true,
              color: "#152C5B",
              fontSize: 12,
            },
            data: datas || [
              {
                value: 40,
                name: "一期客服楼宇(7,10栋)\n比例:36%",
              },
              {
                value: 38,
                name: "一期客服楼宇(7,11栋)\n比例:36%",
              },
            ],
          },
        ],
      };
      this.myChart.setOption(option, false);
    },
  },
};
</script>

<style lang="less" scoped>
.park-water-overview {
  height: 570px;
  background: #ffffff;
  border-radius: 10px;
  padding: 14px 20px;
  .card-container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    .single-card {
      flex: 1;
      text-align: center;
      .value-line {
        height: 22px;
        font-size: 22px;
        font-family: Helvetica-Bold, Helvetica;
        font-weight: bold;
        color: #152c5b;
        line-height: 22px;
        margin-bottom: 8px;
      }
      .label-line {
        height: 14px;
        font-size: 14px;
        font-weight: 400;
        color: #8190ac;
        line-height: 14px;
      }
    }
  }
  .title-line {
    height: 16px;
    line-height: 16px;
    margin-bottom: 20px;
    display: flex;
    .title-label {
      font-size: 14px;
      font-family: Source Han Sans SC-Bold, Source Han Sans SC;
      font-weight: bold;
      color: #152c5b;
    }
    img {
      height: 14px;
      margin-right: 6px;
    }
  }
  .first-title {
    margin-top: 26px;
  }
  .second-title {
    margin-top: 40px;
  }
  .third-title {
    margin-top: 50px;
  }
  .chart-container {
    height: 210px;
    width: 100%;
  }
}
</style>
<template>
  <div class="analysis-water-proportion">
    <WaterEnergyTitle title="各楼栋用水量比较分析" />
    <div class="chart-container" id="buildingWaterCompare"></div>
  </div>
</template>

<script>
import WaterEnergyTitle from "@/components/WaterEnergyTitle";
import * as echarts from "echarts";
export default {
  name: "analysisWaterProportion",
  components: {
    WaterEnergyTitle,
  },
  mounted() {
    this.initCharts();
  },
  methods: {
    initCharts(xAxisList, totalList, rentList) {
      const chartDom = document.getElementById("buildingWaterCompare");
      this.myChart = echarts.init(chartDom);
      let option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "none",
          },
        },
        grid: {
          left: "40",
          right: "10",
          bottom: "20",
        },
        xAxis: {
          type: "category",
          data: xAxisList || [],
          axisLabel: {
            interval: 0,
            formatter: (value, index) => {
              const returnStr = `${value}\n{${
                this.annuallyFloatList[index]
              }|}${this.annuallyGrowthRateList[index].toFixed(2)}%`;
              return returnStr;
            },
            margin: 10,
            showMinLabel: true,
            rich: {
              equals: {
                lineHeight: 50,
                height: 20,
                width: 20,
                verticalAlign: "middle",
                backgroundColor: {
                  image: require("@/assets/images/bms/state-equals-offset.png"),
                },
              },
              up: {
                lineHeight: 50,
                height: 20,
                width: 20,
                verticalAlign: "middle",
                backgroundColor: {
                  image: require("@/assets/images/bms/state-up-offset.png"),
                },
              },
              down: {
                lineHeight: 50,
                height: 20,
                width: 20,
                verticalAlign: "middle",
                backgroundColor: {
                  image: require("@/assets/images/bms/state-down-offset.png"),
                },
              },
            },
          },
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "可租面积",
            data: totalList || [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            type: "bar",
            itemStyle: {
              normal: {
                color: "#EEF3FD", // 定义柱形的背景色
                barBorderRadius: [10, 10, 0, 0], // 定义背景柱形的圆角
              },
            },
            emphasis: {
              itemStyle: {
                color: "#EEF3FD",
              },
            },
            label: {
              normal: {
                show: true, //是否显现，不显示的话设置成false
                position: "top", //显示的位置
                distance: 10, //距离侄子的值 // label要显示的值比如： 20%
                formatter: function (param) {
                  return param.value;
                },
                textStyle: {
                  //这个地方颜色是支持回调函数的这种的，如果是一种颜色则可以写成： color :'#1089E7'
                  color: "#8993A4",
                  fontSize: "16",
                },
              },
            },
            barGap: "-100%", // 设置柱形重合的重要步骤
            z: 1,
            silent: false,
            animation: true, // 关闭动画效果
            barWidth: "32px", // 设置柱形宽度
          },
          {
            name: "已租面积",
            type: "bar",
            data: rentList || [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            barWidth: "32px",
            barGap: "-100%", // 设置柱形重合的重要步骤
            itemStyle: {
              normal: {
                color: "#1A4CEC", // 定义柱形的背景色
                barBorderRadius: [10, 10, 0, 0], // 定义柱形的圆角
              },
            },
          },
        ],
      };
      this.myChart.setOption(option, false);
    },
  },
};
</script>

<style lang="less" scoped>
.analysis-water-proportion {
  height: 270px;
  background: #ffffff;
  border-radius: 10px;
  padding: 14px 20px;
  .chart-container {
    width: 100%;
    height: 210px;
    margin-top: 10px;
  }
}
</style>
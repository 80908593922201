var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "economic-benefits-component" },
    [
      _c("WaterEnergyTitle", { attrs: { title: "园区中水系统的经济效益" } }),
      _c("div", { staticClass: "detail-container" }, [
        _c("div", [
          _c("div", { staticClass: "value-line" }, [
            _c("span", { staticClass: "value-span" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm._f("addCommas")(_vm.totalCount)) +
                  "\n        "
              ),
            ]),
            _c("span", { staticClass: "unit-span" }, [_vm._v(" 元 ")]),
          ]),
          _c("div", { staticClass: "tips-line" }, [
            _vm._v("经济效益 = 中水总用水量*市政水单价"),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
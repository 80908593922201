var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "app-container water-energy-consumption" }, [
    _c("div", { staticClass: "search-line" }, [_c("WaterEnergySearch")], 1),
    _c(
      "div",
      { staticClass: "card-container" },
      [
        _c(
          "el-row",
          { attrs: { gutter: 30 } },
          [
            _c("el-col", { attrs: { span: 10 } }, [_c("WaterOutTotal")], 1),
            _c("el-col", { attrs: { span: 14 } }, [_c("WaterInTotal")], 1),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "card-container" },
      [
        _c(
          "el-row",
          { attrs: { gutter: 30 } },
          [
            _c(
              "el-col",
              { attrs: { span: 16 } },
              [
                _c("div", [_c("WaterMonthTrend")], 1),
                _c(
                  "el-row",
                  {
                    staticStyle: { "margin-top": "20px" },
                    attrs: { gutter: 30 },
                  },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [_c("AnalysisOfWaterInflowProportion")],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [_c("ComparativeAnalysisOfWater")],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("el-col", { attrs: { span: 8 } }, [_c("ParkWaterOverview")], 1),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "card-container" },
      [
        _c(
          "el-row",
          { attrs: { gutter: 30 } },
          [
            _c("el-col", { attrs: { span: 16 } }, [_c("BuildingFourTrend")], 1),
            _c("el-col", { attrs: { span: 8 } }, [_c("EconomicBenefits")], 1),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="economic-benefits-component">
    <WaterEnergyTitle title="园区中水系统的经济效益" />
    <div class="detail-container">
      <div>
        <div class="value-line">
          <span class="value-span">
            {{ totalCount | addCommas }}
          </span>
          <span class="unit-span"> 元 </span>
        </div>
        <div class="tips-line">经济效益 = 中水总用水量*市政水单价</div>
      </div>
    </div>
  </div>
</template>

<script>
import WaterEnergyTitle from "@/components/WaterEnergyTitle";
export default {
  name: "economicBenefitsComponent",
  components: {
    WaterEnergyTitle,
  },
  data() {
    return {
      totalCount: 1827216,
    };
  },
};
</script>

<style lang="less" scoped>
.economic-benefits-component {
  height: 270px;
  background: #fff;
  border-radius: 10px;
  padding: 14px 20px;
  .detail-container {
    height: 215px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .value-line {
      text-align: center;
      height: 48px;
      font-size: 48px;
      color: #2a61ff;
      line-height: 48px;
      display: inline-block;
      margin-bottom: 20px;
      .value-span {
        font-weight: bold;
      }
      .unit-span {
        font-size: 0.3em;
        display: inline-block;
      }
    }
    .tips-line {
      text-align: center;
      height: 14px;
      font-size: 12px;
      font-weight: 400;
      color: #8190ac;
      line-height: 14px;
    }
  }
}
</style>